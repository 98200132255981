import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useTreeExchangeStyles = makeStyles((theme: Theme) => ({
  treeExchange: {
    zIndex: '1000',
    backgroundColor: theme.palette.background.paper,
    color: `${theme.palette.text.primary} !important`,
    '& .node.focused': {
      backgroundColor: `${theme.palette.background.paper} !important`,
    },
  },
}));

export default useTreeExchangeStyles;
