import { useCallback } from 'react';

interface SmoothScrollOptions {
  duration?: number;
  direction?: 'up' | 'down';
}

export const useSmoothScroll = () => {
  const smoothScroll = useCallback((options: SmoothScrollOptions = {}) => {
    const { 
      duration = 1000,
      direction = 'down'
    } = options;

    const windowHeight = window.innerHeight;
    const startPosition = window.pageYOffset || document.documentElement.scrollTop;
    const targetPosition = direction === 'down' 
      ? startPosition + windowHeight 
      : startPosition - windowHeight;
    const startTime = performance.now();

    const easeInOutCubic = (t: number): number => 
      t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;

    const animateScroll = (currentTime: number) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      
      const currentPosition = startPosition + 
        (targetPosition - startPosition) * easeInOutCubic(progress);
      
      window.scrollTo(0, currentPosition);

      if (progress < 1) {
        window.requestAnimationFrame(animateScroll);
      }
    };

    window.requestAnimationFrame(animateScroll);
  }, []);

  return {
    smoothScrollDown: (duration?: number) => smoothScroll({ direction: 'down', duration }),
    smoothScrollUp: (duration?: number) => smoothScroll({ direction: 'up', duration }),
    smoothScroll
  };
};