// @ts-nocheck
const getPath = e => {
  if (e.path) return e.path

  let elem = e.target
  const path = [elem]

  while (elem.parentElement) {
    elem = elem.parentElement
    path.unshift(elem)
  }

  return path
}

export default (e: any, node: any) => {
  if (!(e instanceof Event)) return false
  return !getPath(e).some((eventNode: any) => eventNode === node)
}
