export {};

declare global {
  interface Array<T> {
    unique(): Array<T>;
  }
}

Array.prototype.unique = function<T>(): Array<T> {
  return [...new Set(this)];
}