import axios from 'axios';
import { number } from 'yup';

const config = { headers: { 'Content-Type': 'application/json' } };

const getSubscriptionManagementUrl = async () => {
    const URL = `${process.env.REACT_APP_SERVER_URL}/Subscription/GetSubscriptionManagementUrl`;
    const result = await axios.get(URL, config);
    return result;
};

const getSubscriptionLevel = async (): Promise<number> => {
    const URL = `${process.env.REACT_APP_SERVER_URL}/Subscription/Level`;
    const result = await axios.get<number>(URL, config);
    return result.data;
};

const canSeeStiScore = async (): Promise<boolean> => {
    const URL = `${process.env.REACT_APP_SERVER_URL}/Subscription/CanSeeStiScore`;
    const result = await axios.get<boolean>(URL, config);
    return result.data;
};

const handleNewOrderCreated = async (orderId: string) => {
    const URL = `${process.env.REACT_APP_SERVER_URL}/Subscription/HandleNewOrderCreated/${orderId}`;
    await axios.post(URL, config);
};

const isPremium = (level: number) => {
    return level == 2 || level == 3
}

const SubscriptionService = {
    getSubscriptionManagementUrl,
    handleNewOrderCreated,
    getSubscriptionLevel,
    isPremium,
    canSeeStiScore
};

export default SubscriptionService;
