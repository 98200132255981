// @ts-nocheck

const identity = c => c

export default (node, childProp = 'children', childSelector = identity) => {
  const children = node[childProp]
  if (!children || children.length === 0) return false
  
  const checkedCount = children.filter(c => childSelector(c).checked).length
  
  // partial means some but not all children are checked
  if (checkedCount > 0 && checkedCount < children.length) return true
  
  // or if any child is partial
  return children.some(c => childSelector(c).partial)
}