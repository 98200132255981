import { Box, Button, Chip, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import useUserCultureData from '../../../../hooks/useUserCultureData';
import ExchangesByCountry, { Constituent } from '../../../../models/dtos/exchangesByCountry';
import DataGridService from '../../../../services/dataGridService';
import useTreeExchangeStyles from '../../styles/useTreeExchangeStyles';
import './TreeExchangeSelect.scss';
import { TreeNode } from '../../../dropdownTree/types/types';
import DropdownTreeSelect from '../../../dropdownTree';

interface TreeExchangeSelectPropsModel {
  selectedExchanges: string[],
  selectedIndexes: string[],
  setSelectedExchanges: React.Dispatch<React.SetStateAction<string[]>>,
  setSelectedIndexes: React.Dispatch<React.SetStateAction<string[]>>,
  constituentsTreeData: TreeNode[],
  setConstituentsTreeData: React.Dispatch<React.SetStateAction<TreeNode[]>>,
  isLoading: boolean,
}

export default function TreeExchangeSelect({
  selectedExchanges,
  selectedIndexes,
  setSelectedExchanges,
  setSelectedIndexes,
  constituentsTreeData,
  setConstituentsTreeData,
  isLoading,
}: TreeExchangeSelectPropsModel) {
  const { data: userIpData } = useUserCultureData();
  const { data: constituentsQuery } = useQuery(['getConstituents'], () => DataGridService.getExchanges());
  const classes = useTreeExchangeStyles();
  const theme = useTheme();
  const countryNames: { [key: string]: string } = {
    ad: 'Andorra',
    ae: 'United Arab Emirates',
    af: 'Afghanistan',
    ag: 'Antigua and Barbuda',
    al: 'Albania',
    am: 'Armenia',
    ao: 'Angola',
    aq: 'Antarctica',
    ar: 'Argentina',
    as: 'American Samoa',
    at: 'Austria',
    au: 'Australia',
    aw: 'Aruba',
    ax: 'Åland Islands',
    az: 'Azerbaijan',
    ba: 'Bosnia and Herzegovina',
    bb: 'Barbados',
    bd: 'Bangladesh',
    be: 'Belgium',
    bf: 'Burkina Faso',
    bg: 'Bulgaria',
    bh: 'Bahrain',
    bi: 'Burundi',
    bj: 'Benin',
    bl: 'Saint Barthélemy',
    bm: 'Bermuda',
    bn: 'Brunei',
    bo: 'Bolivia',
    br: 'Brazil',
    bs: 'Bahamas',
    bt: 'Bhutan',
    bv: 'Bouvet Island',
    bw: 'Botswana',
    by: 'Belarus',
    bz: 'Belize',
    ca: 'Canada',
    cc: 'Cocos Islands',
    cd: 'Democratic Republic of the Congo',
    cf: 'Central African Republic',
    cg: 'Republic of the Congo',
    ch: 'Switzerland',
    ci: 'Ivory Coast',
    ck: 'Cook Islands',
    cl: 'Chile',
    cm: 'Cameroon',
    cn: 'China',
    co: 'Colombia',
    cu: 'Cuba',
    cv: 'Cape Verde',
    cw: 'Curaçao',
    cx: 'Christmas Island',
    cy: 'Cyprus',
    cz: 'Czech Republic',
    de: 'Germany',
    dj: 'Djibouti',
    dk: 'Denmark',
    dm: 'Dominica',
    do: 'Dominican Republic',
    dz: 'Algeria',
    ec: 'Ecuador',
    ee: 'Estonia',
    eg: 'Egypt',
    eh: 'Western Sahara',
    er: 'Eritrea',
    es: 'Spain',
    et: 'Ethiopia',
    fi: 'Finland',
    fj: 'Fiji',
    fk: 'Falkland Islands',
    fm: 'Micronesia',
    fo: 'Faroe Islands',
    fr: 'France',
    ga: 'Gabon',
    gb: 'United Kingdom',
    gd: 'Grenada',
    ge: 'Georgia',
    gf: 'French Guiana',
    gg: 'Guernsey',
    gh: 'Ghana',
    gi: 'Gibraltar',
    gl: 'Greenland',
    gm: 'Gambia',
    gn: 'Guinea',
    gp: 'Guadeloupe',
    gq: 'Equatorial Guinea',
    gr: 'Greece',
    gs: 'South Georgia and South Sandwich Islands',
    gt: 'Guatemala',
    gu: 'Guam',
    gw: 'Guinea-Bissau',
    gy: 'Guyana',
    hk: 'Hong Kong',
    hm: 'Heard Island and McDonald Islands',
    hn: 'Honduras',
    hr: 'Croatia',
    ht: 'Haiti',
    hu: 'Hungary',
    id: 'Indonesia',
    ie: 'Ireland',
    il: 'Israel',
    im: 'Isle of Man',
    in: 'India',
    io: 'British Indian Ocean Territory',
    iq: 'Iraq',
    ir: 'Iran',
    is: 'Iceland',
    it: 'Italy',
    je: 'Jersey',
    jm: 'Jamaica',
    jo: 'Jordan',
    jp: 'Japan',
    ke: 'Kenya',
    kg: 'Kyrgyzstan',
    kh: 'Cambodia',
    ki: 'Kiribati',
    km: 'Comoros',
    kn: 'Saint Kitts and Nevis',
    kp: 'North Korea',
    kr: 'South Korea',
    kw: 'Kuwait',
    ky: 'Cayman Islands',
    kz: 'Kazakhstan',
    la: 'Laos',
    lb: 'Lebanon',
    lc: 'Saint Lucia',
    li: 'Liechtenstein',
    lk: 'Sri Lanka',
    lr: 'Liberia',
    ls: 'Lesotho',
    lt: 'Lithuania',
    lu: 'Luxembourg',
    lv: 'Latvia',
    ly: 'Libya',
    ma: 'Morocco',
    mc: 'Monaco',
    md: 'Moldova',
    me: 'Montenegro',
    mf: 'Saint Martin',
    mg: 'Madagascar',
    mh: 'Marshall Islands',
    mk: 'North Macedonia',
    ml: 'Mali',
    mm: 'Myanmar',
    mn: 'Mongolia',
    mo: 'Macao',
    mp: 'Northern Mariana Islands',
    mq: 'Martinique',
    mr: 'Mauritania',
    ms: 'Montserrat',
    mt: 'Malta',
    mu: 'Mauritius',
    mv: 'Maldives',
    mw: 'Malawi',
    mx: 'Mexico',
    my: 'Malaysia',
    mz: 'Mozambique',
    na: 'Namibia',
    nc: 'New Caledonia',
    ne: 'Niger',
    nf: 'Norfolk Island',
    ng: 'Nigeria',
    ni: 'Nicaragua',
    nl: 'Netherlands',
    no: 'Norway',
    np: 'Nepal',
    nr: 'Nauru',
    nu: 'Niue',
    nz: 'New Zealand',
    om: 'Oman',
    pa: 'Panama',
    pe: 'Peru',
    pf: 'French Polynesia',
    pg: 'Papua New Guinea',
    ph: 'Philippines',
    pk: 'Pakistan',
    pl: 'Poland',
    pm: 'Saint Pierre and Miquelon',
    pn: 'Pitcairn',
    pr: 'Puerto Rico',
    ps: 'Palestine',
    pt: 'Portugal',
    pw: 'Palau',
    py: 'Paraguay',
    qa: 'Qatar',
    re: 'Réunion',
    ro: 'Romania',
    rs: 'Serbia',
    ru: 'Russia',
    rw: 'Rwanda',
    sa: 'Saudi Arabia',
    sb: 'Solomon Islands',
    sc: 'Seychelles',
    sd: 'Sudan',
    se: 'Sweden',
    sg: 'Singapore',
    sh: 'Saint Helena',
    si: 'Slovenia',
    sj: 'Svalbard and Jan Mayen',
    sk: 'Slovakia',
    sl: 'Sierra Leone',
    sm: 'San Marino',
    sn: 'Senegal',
    so: 'Somalia',
    sr: 'Suriname',
    ss: 'South Sudan',
    st: 'Sao Tome and Principe',
    sv: 'El Salvador',
    sx: 'Sint Maarten',
    sy: 'Syria',
    sz: 'Eswatini',
    tc: 'Turks and Caicos Islands',
    td: 'Chad',
    tf: 'French Southern Territories',
    tg: 'Togo',
    th: 'Thailand',
    tj: 'Tajikistan',
    tk: 'Tokelau',
    tl: 'Timor-Leste',
    tm: 'Turkmenistan',
    tn: 'Tunisia',
    to: 'Tonga',
    tr: 'Turkey',
    tt: 'Trinidad and Tobago',
    tv: 'Tuvalu',
    tw: 'Taiwan',
    tz: 'Tanzania',
    ua: 'Ukraine',
    ug: 'Uganda',
    um: 'United States Minor Outlying Islands',
    us: 'United States',
    uy: 'Uruguay',
    uz: 'Uzbekistan',
    va: 'Vatican City',
    vc: 'Saint Vincent and the Grenadines',
    ve: 'Venezuela',
    vg: 'British Virgin Islands',
    vi: 'U.S. Virgin Islands',
    vn: 'Vietnam',
    vu: 'Vanuatu',
    wf: 'Wallis and Futuna',
    ws: 'Samoa',
    ye: 'Yemen',
    yt: 'Mayotte',
    za: 'South Africa',
    zm: 'Zambia',
    zw: 'Zimbabwe'
  };
  const countryCodes = ['ru', 're', 'ee', 'no', 'ad', 'ae', 'af', 'ag', 'al', 'am', 'ao', 'aq', 'ar', 'as', 'at', 'au', 'aw', 'ax', 'az', 'ba', 'bb', 'bd', 'be', 'bf', 'bg', 'bh', 'bi', 'bj', 'bl', 'bm', 'bn', 'bo', 'br', 'bs', 'bt', 'bv', 'bw', 'by', 'bz', 'ca', 'cc', 'cd', 'cf', 'cg', 'ch', 'ci', 'ck', 'cl', 'cm', 'cn', 'co', 'cu', 'cv', 'cw', 'cx', 'cy', 'cz', 'de', 'dj', 'dk', 'dm', 'do', 'dz', 'ec', 'eg', 'eh', 'er', 'es', 'et', 'fi', 'fj', 'fk', 'fm', 'fo', 'fr', 'ga', 'gb', 'gd', 'gf', 'gg', 'gh', 'gi', 'gl', 'gm', 'gn', 'gp', 'gq', 'gr', 'gs', 'gt', 'gu', 'gw', 'gy', 'hk', 'hm', 'hn', 'hr', 'ht', 'hu', 'id', 'ie', 'il', 'im', 'in', 'io', 'iq', 'ir', 'is', 'it', 'je', 'jm', 'jo', 'jp', 'ke', 'kg', 'kh', 'ki', 'km', 'kn', 'kp', 'kr', 'kw', 'la', 'lb', 'lc', 'li', 'lk', 'lr', 'ls', 'lt', 'lu', 'lv', 'ma', 'mc', 'md', 'me', 'mf', 'mg', 'mh', 'mk', 'ml', 'mm', 'mn', 'mo', 'mp', 'mq', 'mr', 'ms', 'mt', 'mu', 'mv', 'mw', 'mx', 'my', 'mz', 'na', 'nc', 'ne', 'nf', 'ng', 'ni', 'nl', 'om', 'pa', 'pe', 'pf', 'pg', 'ph', 'pk', 'pl', 'pm', 'pn', 'pr', 'ps', 'pt', 'pw', 'py', 'qa', 're', 'ro', 'rs', 'rw', 'sa', 'sb', 'sc', 'sd', 'se', 'sg', 'sh', 'si', 'sj', 'sl', 'sm', 'sn', 'so', 'sr', 'ss', 'st', 'sv', 'sx', 'sy', 'sz', 'tc', 'td', 'tf', 'tg', 'th', 'tj', 'tk', 'tl', 'tm', 'tn', 'to', 'tr', 'tt', 'tw', 'tz', 'ua', 'ug', 'um', 'us', 'uy', 'uz', 'va', 'vc', 've', 'vg', 'vi', 'vn', 'vu', 'wf', 'ws', 'ye', 'yt', 'za', 'zm', 'zw'];

  // Helper function to get country name from code
  const getCountryName = (code: string): string => {
    return countryNames[code.toLowerCase()] || code.toUpperCase();
  };

  useEffect(() => {
    if (constituentsQuery) {
      const { country } = userIpData ?? { country: 'US' };
      const newConstituentsTreeData = [] as any[];
      let newChoosenExchange: string[] = [country];
      let newChoosenIndexes: string[] = [];

      constituentsQuery.forEach((elem: ExchangesByCountry) => {
        newConstituentsTreeData.push({
          label: elem.country,
          displayLabel: getCountryName(elem.country), // Add display label for country name
          checked: elem.country === country,
          className: `flag-${elem.country.toLowerCase()}`,
          tagClassName: `flag-${elem.country.toLowerCase()}`,
          children: elem.constituents.map((constituent: Constituent) => {
            const constituentSymbol = constituent.symbol;
            if (country === 'US' && elem.country === country) {
              newChoosenIndexes = ['S&P500'];
            } else if (elem.country === country) {
              newChoosenExchange.push(constituentSymbol);
            }
            return {
              label: constituentSymbol,
              checked: (country !== 'US' && elem.country === country) || (elem.country === country && constituentSymbol === 'S&P500'),
              tagClassName: `flag-${elem.country.toLowerCase()}`,
              type: constituent.type,
            };
          }),
        });
      });

      if (newChoosenIndexes.length > 0) {
        setSelectedIndexes(newChoosenIndexes);
      }
      else {
        setSelectedExchanges(newChoosenExchange);
      }
      setConstituentsTreeData(newConstituentsTreeData);
    }
  }, [constituentsQuery, userIpData]);

  useEffect(() => {
    if (constituentsTreeData.length > 0) {
      setConstituentsTreeData((oldModel) => (oldModel.map((elem) => ({
        ...elem,
        checked: (selectedExchanges.includes(elem.label) || selectedIndexes.includes(elem.label)),
        disabled: isLoading,
        children: elem.children.map((child: any) => ({
          ...child,
          checked: (selectedExchanges.includes(child.label) || selectedIndexes.includes(child.label)),
          disabled: isLoading,
        })),
      }))));
    }
  }, [isLoading]);

  function onChange(currentNode: TreeNode) {
    // Get all constituents that need to be switched
    let constituentsToSwitch = [];
    if (currentNode._depth === 0) {
      // If it's a country node, get all its children
      constituentsToSwitch = (constituentsTreeData.find((elem: any) => (
        elem.label === currentNode.label
      )) as any).children.map((elem: any) => ({ symbol: elem.label, type: elem.type }));
    }
    // Add the current node itself
    constituentsToSwitch.push({ symbol: currentNode.label, type: currentNode.type });

    // Separate indexes and exchanges
    const exchangesToSwitch = [] as string[];
    const indexesToSwitch = [] as string[];
    constituentsToSwitch.forEach((constituent: Constituent) => {
      if (constituent.type === 'Index') {
        indexesToSwitch.push(constituent.symbol);
      } else {
        exchangesToSwitch.push(constituent.symbol);
      }
    });

    if (currentNode.checked) {
      // Handle selection
      setSelectedExchanges(prev => [...exchangesToSwitch, ...prev]);
      setSelectedIndexes(prev => [...indexesToSwitch, ...prev]);
    } else {
      // Handle deselection
      if (currentNode._depth === 0) {
        // If it's a country node, remove the country and all its exchanges and indexes
        setSelectedExchanges(prev =>
          prev.filter(exchange =>
            !exchangesToSwitch.includes(exchange) && exchange !== currentNode.label
          )
        );
        setSelectedIndexes(prev =>
          prev.filter(index => !indexesToSwitch.includes(index))
        );
        // Update the tree state for the country
        updateCountryInTree(currentNode.label, false);
      } else {
        // If it's an exchange or index node
        setSelectedExchanges(prev => {
          const newExchanges = prev.filter(exchange =>
            !exchangesToSwitch.includes(exchange)
          );

          // Find the parent country
          const parentCountry = constituentsTreeData.find(country =>
            country.children.some((child: any) => child.label === currentNode.label)
          );

          if (parentCountry) {
            const shouldBeSelected = shouldCountryBeSelected(parentCountry.label, selectedExchanges, selectedIndexes);

            if (!shouldBeSelected) {
              updateCountryInTree(parentCountry.label, false);
            }
          }

          return newExchanges;
        });

        setSelectedIndexes(prev => {
          const newIndexes = prev.filter(index =>
            !indexesToSwitch.includes(index)
          );

          // Find the parent country
          const parentCountry = constituentsTreeData.find(country =>
            country.children.some((child: any) => child.label === currentNode.label)
          );

          if (parentCountry) {
            // Check if this country still has any selected items
            const shouldBeSelected = shouldCountryBeSelected(parentCountry.label, selectedExchanges, selectedIndexes);

            if (!shouldBeSelected) {
              updateCountryInTree(parentCountry.label, false);
            }
          }

          return newIndexes;
        });
      }
    }
  }

  function getClassName(label: string) {
    // First, check if it's a top-level label
    const parentNode = constituentsTreeData.find(item => item.label === label);
    if (parentNode) {
      return parentNode.className;
    }

    // If not found in parent nodes, search in children
    for (const parent of constituentsTreeData) {
      const childNode = parent.children.find((child: { label: any; }) => child.label === label);
      if (childNode) {
        return childNode.tagClassName;
      }
    }

    // Return undefined if label is not found in either parents or children
    return undefined;
  };

  const dropdownTree = useMemo(() => (
    <DropdownTreeSelect
      data={constituentsTreeData}
      className={`${classes.treeExchange}`}
      keepOpenOnSelect
      clearSearchOnChange
      onChange={onChange}
      inlineSearchInput={false}
    />
  ), [constituentsTreeData, theme]);

  const getSelectedCountries = (exchanges: string[], indexes: string[], treeData: TreeNode[]): string[] => {
    const selectedCountries = new Set<string>();

    // Helper to check if a label belongs to a country
    const isCountryCode = (label: string) => countryCodes.includes(label.toLowerCase());

    // Add countries that have selected exchanges or indexes
    treeData.forEach((country) => {
      const countryCode = country.label;

      // Check if country has any selected exchanges or indexes
      const hasSelectedItems = country.children.some((child: any) =>
        exchanges.includes(child.label) || indexes.includes(child.label)
      );

      if (hasSelectedItems) {
        selectedCountries.add(countryCode);
      }
    });

    return Array.from(selectedCountries);
  };

  const shouldCountryBeSelected = (countryCode: string, exchanges: string[], indexes: string[]) => {
    const countryNode = constituentsTreeData.find(item => item.label === countryCode);
    if (!countryNode) return false;

    return countryNode.children.some((child: any) =>
      exchanges.includes(child.label) || indexes.includes(child.label)
    );
  };

  const updateCountryInTree = (countryCode: string, shouldBeSelected: boolean) => {
    setConstituentsTreeData(prev => prev.map(item => {
      if (item.label === countryCode) {
        return {
          ...item,
          checked: shouldBeSelected,
          children: item.children.map((child: any) => ({
            ...child,
            checked: shouldBeSelected ?
              selectedExchanges.includes(child.label) || selectedIndexes.includes(child.label) :
              false
          }))
        };
      }
      return item;
    }));
  };

  const handleCountryDelete = (countryToDelete: string) => {
    // Find all exchanges and indexes associated with this country
    const countryNode = constituentsTreeData.find(item => item.label === countryToDelete);
    if (countryNode) {
      const exchangesToRemove = countryNode.children
        .filter((child: any) => child.type !== 'Index')
        .map((child: any) => child.label);

      const indexesToRemove = countryNode.children
        .filter((child: any) => child.type === 'Index')
        .map((child: any) => child.label);

      // Remove all exchanges and indexes from this country AND the country code itself
      setSelectedExchanges(prev =>
        prev.filter(exchange =>
          !exchangesToRemove.includes(exchange) && exchange !== countryToDelete
        )
      );

      setSelectedIndexes(prev =>
        prev.filter(index => !indexesToRemove.includes(index))
      );

      // Update the tree data to uncheck the country and its children
      updateCountryInTree(countryToDelete, false);
    }
  };

  const handleExchangeDelete = (exchangeToDelete: string) => {
    setSelectedExchanges(prev => {
      const newExchanges = prev.filter(e => e !== exchangeToDelete);

      // Find which country this exchange belongs to
      const parentCountry = constituentsTreeData.find(country =>
        country.children.some((child: any) => child.label === exchangeToDelete)
      );

      if (parentCountry) {
        // Check if this country still has any selected items
        const hasSelectedItems = parentCountry.children.some((child: any) => (
          (newExchanges.includes(child.label)) ||
          (selectedIndexes.some(index => index === child.label))
        ));

        // If no more selected items, remove the country and update tree
        if (!hasSelectedItems) {
          updateCountryInTree(parentCountry.label, false);
          return newExchanges.filter(exchange => exchange !== parentCountry.label);
        }
      }

      return newExchanges;
    });
  };

  const handleIndexDelete = (indexToDelete: string) => {
    setSelectedIndexes(prev => {
      const newIndexes = prev.filter(e => e !== indexToDelete);

      // Find which country this index belongs to
      const parentCountry = constituentsTreeData.find(country =>
        country.children.some((child: any) => child.label === indexToDelete)
      );

      if (parentCountry) {
        // Check if this country still has any selected items
        const hasSelectedItems = parentCountry.children.some((child: any) => (
          (selectedExchanges.includes(child.label)) ||
          (newIndexes.includes(child.label))
        ));

        // If no more selected items, remove the country and update tree
        if (!hasSelectedItems) {
          updateCountryInTree(parentCountry.label, false);
          setSelectedExchanges(prev =>
            prev.filter(exchange => exchange !== parentCountry.label)
          );
        }
      }

      return newIndexes;
    });
  };

  const selectedValues = useMemo(() => {
    const displayedCountries = getSelectedCountries(selectedExchanges, selectedIndexes, constituentsTreeData);

    return (
      <Stack direction={'column'} sx={{ width: '100%', paddingLeft: '20px' }}>
        <Box>
          <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
            Countries:
          </Typography>
          <Box sx={{ background: 'rgba(249, 249, 249, 1)', minHeight: '32px' }}>
            {displayedCountries.unique().map(item => {
              return <Chip
                key={item}
                label={
                  <span className="node-label" style={{ marginLeft: '5px' }}>
                    {getCountryName(item)}
                  </span>
                }
                onDelete={() => handleCountryDelete(item)}
                variant="outlined"
                className={getClassName(item)}
              />
            })}
          </Box>
        </Box>
        <Box>
          <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
            Exchanges:
          </Typography>
          <Box sx={{ background: 'rgba(249, 249, 249, 1)', minHeight: '32px' }}>
            {selectedExchanges.filter(e => !countryCodes.includes(e.toLowerCase()))
              .unique()
              .map(item => {
                return <Chip
                  key={item}
                  label={
                    <span className="node-label" style={{ marginLeft: '5px' }}>
                      {item}
                    </span>
                  }
                  onDelete={() => handleExchangeDelete(item)}
                  variant="outlined"
                  className={getClassName(item)}
                />
              })}
          </Box>
        </Box>
        <Box>
          <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
            Indexes:
          </Typography>
          <Box sx={{ background: 'rgba(249, 249, 249, 1)', minHeight: '32px' }}>
            {selectedIndexes.unique().map(item => {
              return <Chip
                key={item}
                label={
                  <span className="node-label" style={{ marginLeft: '5px' }}>
                    {item}
                  </span>
                }
                onDelete={() => handleIndexDelete(item)}
                variant="outlined"
                className={getClassName(item)}
              />
            })}
          </Box>
        </Box>
      </Stack>
    );
  }, [constituentsTreeData, selectedExchanges, selectedIndexes]);

  return (
    <Grid container sx={{ padding: '10px' }}>
      <Grid item xs={12} lg={3}>
        <Box sx={{ borderColor: 'rgba(0, 0, 0, 0.06)', borderStyle: 'solid', borderWidth: '1px', borderRadius: '8px', width: '100%' }}>
          {dropdownTree}
        </Box>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Box>
          {selectedValues}
        </Box>
      </Grid>

    </Grid>
  );
}
