import { Box, Button, Checkbox, FormControlLabel, Grid, Stack, Tooltip, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

interface Props {
    selectedWatchlists: string[],
    watchlists: any[],
    setSelectedWatchlists: React.Dispatch<React.SetStateAction<string[]>>,
    setExpanded: React.Dispatch<React.SetStateAction<string>>,
    isLoading: boolean,
}


export default function WatchlistsSelect({ selectedWatchlists, setSelectedWatchlists, isLoading, watchlists, setExpanded }: Props) {
    const [urlWatchlistAdded, setUrlWatchlistAdded] = useState<boolean>(false);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const watchlistName = searchParams.get('watchlistName');

    useEffect(() => {
        if (isLoading || urlWatchlistAdded)
            return;

        if (watchlistName) {
            setSelectedWatchlists((oldValue) => [...oldValue, watchlistName])
            setUrlWatchlistAdded(true);
            setExpanded('watchlist')
        }
    }, [watchlistName, isLoading]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelectedWatchlists((oldWatchlists) => [...oldWatchlists, event.target.id])
        } else {
            setSelectedWatchlists((oldWatchlists) => oldWatchlists.filter(w => w !== event.target.id))
        }
    };

    const handleSelectAllClick = () => {
        setSelectedWatchlists(watchlists.map(w => w.name))
    };

    const handleClearClick = () => {
        setSelectedWatchlists([])
    };

    return (
        <Box sx={{ width: '100%', marginLeft: '30px', paddingBottom: '30px'}}>
            <Grid container>
                {watchlists?.map(watchlist =>
                (<Grid item xs={2} key={watchlist.id}>
                    <Tooltip title={watchlist.name} arrow>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id={watchlist.name}
                                    key={watchlist.name}
                                    checked={selectedWatchlists.includes(watchlist.name)}
                                    onChange={handleChange}
                                />
                            }
                            sx={{
                                '.MuiFormControlLabel-label': {
                                    display: 'inline-block',
                                    maxWidth: '100%', // Adjust based on container width
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    verticalAlign: 'middle',
                                    fontSize: '12px'
                                },
                            }}
                            label={
                                watchlist.name.length > 23
                                  ? `${watchlist.name.slice(0, 20)}...`
                                  : watchlist.name
                              }                        />
                    </Tooltip>
                </Grid>))}
            </Grid>
            <Stack direction={"row"} style={{ marginTop: '20px' }} spacing={6}>
                <Button sx={{ color: 'white', width: 'max(140px, 20%)', background: '#2196F3', height: '30px', borderRadius: '4px' }} variant='contained' onClick={handleSelectAllClick} >
                    <Typography sx={{ color: 'white', width: '100%', textAlign: 'center', fontSize: '14px' }} >
                        Select all
                    </Typography>
                </Button>
                <Button sx={{ color: 'white', width: 'max(140px, 20%)', background: '#2196F3', height: '30px', borderRadius: '4px' }} variant='contained' startIcon={<DeleteIcon />} onClick={handleClearClick} >
                    <Typography sx={{ color: 'white', width: '100%', textAlign: 'center', fontSize: '14px' }} >
                        Clear
                    </Typography>
                </Button>
            </Stack>
        </Box>
    );
}